<template>
  <main role="main" :class="$style.main">
    <router-view />
  </main>
</template>

<style lang="scss" module>
.main {
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 62px;
  max-width: 767px;

  @media screen and (max-width: 815px) {
    padding-right: 12px;
    padding-left: 12px;
  }
}
</style>
